<template>
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="currentColor"
      d="M11.6219 4.61563C11.9385 5.19375 12.1766 5.81469 12.3213 6.45938C13.6066 7.12344 14.4972 8.45156 14.4972 9.99688C14.4972 12.2078 12.7081 13.9969 10.4972 13.9969C8.28627 13.9969 6.49721 12.2078 6.49721 9.99688C6.49721 8.28969 7.57533 6.8375 9.08721 6.2625C9.35283 6.80156 9.49346 7.39531 9.49752 7.99688C9.49752 9.16469 8.98565 10.2078 8.18502 10.9381C8.38033 11.4147 8.72034 11.8169 9.15377 12.0944C10.5522 10.8522 11.2203 8.98094 10.9272 7.135C10.6342 5.28344 9.4194 3.71313 7.70533 2.96313L8.99846 0.892813L7.26252 -0.5H3.73127L1.9969 0.894375L3.28596 2.96469C0.907209 4.0125 -0.417166 6.58125 0.118052 9.12813C0.653271 11.675 2.89555 13.4969 5.49618 13.5C5.73446 13.4961 5.97274 13.4805 6.21118 13.4414C7.7268 15.332 10.3049 16.008 12.5549 15.1014C14.8012 14.1992 16.1924 11.9264 15.9768 9.51078C15.7612 7.09516 13.9938 5.10938 11.6219 4.61563ZM3.9969 1.23031L4.26252 0.999844H6.73127L6.9969 1.23031L6.20002 2.55063C5.73502 2.48422 5.25846 2.48422 4.79377 2.55063L3.9969 1.23031ZM1.4969 7.97188C1.5008 5.765 3.29002 3.975 5.4969 3.97188C6.52815 3.97188 7.4619 4.37438 8.17283 5.01875C6.30158 5.89375 4.99783 7.76875 4.99783 9.97188C4.99783 10.6516 5.12674 11.3275 5.37283 11.9603C3.22815 11.9219 1.4969 10.1625 1.4969 7.97188Z"
    />
  </svg>
</template>
